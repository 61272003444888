import React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import axios from 'axios'
import dateFormat from "dateformat";
import { motion } from 'framer-motion';
import ReactAudioPlayer from 'react-audio-player';
import Swal from 'sweetalert2';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { RotatingLines } from 'react-loader-spinner';
import { FiDownload } from 'react-icons/fi';
import AudioPlayer from './AudioPlayer';
import { FaDownload } from 'react-icons/fa';

import AudioDownloadButton from './AudioDownloadButton';


const FilterReport = () => {
  let history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('token')) {
      // getNotes();
      // eslint-disable-next-line
      //const
    } else {
      history('/login');
    }
  }, [])


  useEffect(() => {
    document.title = "Filter Report"
  }, []);
  const tdStyle = {
    padding: '10px', // Example: Adjust padding
    border: '1px solid #ccc', // Example: Add border
    backgroundColor: '#f0f0f0', // Example: Set background color
    color: '#333', // Example: Set text color
  };

  const [startdate, setstartdateval] = useState(new Date());

  const [enddate, setEnddateval] = useState(new Date());

  const [sddate, setsddate] = useState("NA");
  const [eddate, seteddate] = useState("NA");


  const [campaignname, setCampaignname] = useState("ALL");

  const [keypress, setKeypress] = useState("1");

  const [callduration, setCallduration] = useState("0");

  

  const [opennav, setopennav] = useState();
  const [listname, setList] = useState();


  const setstartdate = (event) => {
    //alert(event)
    setstartdateval(event)
    var sttt = dateFormat(event, "yyyy-mm-dd")



    setsddate(sttt)



  }

  const testdownload = async (e1, e2) => {
    try {
      const response = await fetch(process.env.REACT_APP_IP + `/User/download?folderandname=${e1}`, {
        method: 'GET',
        headers: {
          // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = e2;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Error downloading CSV:', response.status);
        setDownloadtrue(false)
      }
      setDownloadtrue(false)
    } catch (error) {
      console.error('Error downloading CSV:', error);
      setDownloadtrue(false)
    }

  }

  const [temptoken, setTemptoken] = useState();

  const userinfotoken = async (e) => {
    setTemptoken('')

    const respp = await fetch(process.env.REACT_APP_IP + "/User/GetUserinfo", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
    })
    const json = await respp.json()
    setTemptoken(json.userpro.tempp)
  }

  useEffect(() => {
    userinfotoken()
  }, [])



  const optionforlist = async (e) => {
    const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllcampname", {
      method: 'POST',
      // headers: {
      //     'Content-Type': 'application/json'
      // },

      headers: {
        'Content-Type': 'application/json',

        "Authorization": `Bearer ${localStorage.getItem('token')}`
      }

      // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
    })
    const json = await respp.json()
    console.log(json.length)

    var elelist = "";

    if (localStorage.getItem('roles') === 'ROLE_ADMIN') {

      elelist = document.getElementById('campnameid');

      for (var i = 0; i < json.length; i++) {
        // POPULATE SELECT ELEMENT WITH JSON.
        elelist.innerHTML = elelist.innerHTML +
          '<option  value="' + json[i]['id'] + '">' + json[i]['username'] + ' | ' + json[i]['campname'] + '</option>';
      }
    } else {

      // for (var i = 0; i < json.length; i++) {
       
      //   elelist.innerHTML = elelist.innerHTML +
      //     '<option  value="' + json[i]['id'] + '">' + json[i]['campname'] + '</option>';
      // }


    }

  }
  useEffect(() => {
    optionforlist()
  }, [])

  const setenddate = (event) => {
    setEnddateval(event)
    var enddd = dateFormat(event, "yyyy-mm-dd")
    seteddate(enddd)

  }

  const toggleClass = () => {
    //alert("clicked");
    if (!opennav) {
      setopennav(true);
    } else {
      setopennav(false);
    }
    // alert("clicked"+opennav);

  }

  const [downloadtrue, setDownloadtrue] = useState(false)

  const filterreportdownload = async () => {

    setDownloadtrue(true)

    // if(campaignname==='ALL')
    // {
    //   Swal.fire({
    //     position: 'top-end',
    //     icon: 'error',
    //     title: 'Select Only 1 Campaign',
    //     background: '#e9ecef',
    //    // title: 'Done Successfully ',
    //    //background: primary,
    //     showConfirmButton: false,
    //     timer: 4500
    //   })
    //   setDownloadtrue(false)
    //   return;
    // }


    //process.env.REACT_APP_IP+`/User/checkreport?pageNumber=${offset - 1}&&
    //size=10&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&campid=${campaignname}`

    try {
      const response = await fetch(process.env.REACT_APP_IP + `/User/reportdownloadbycampid?campid=${campaignname}&&keypress=${keypress}&&startdate=${sddate}&&enddate=${eddate}`, {
        method: 'GET',
        headers: {
          // 'Authorization': `Bearer ${token}`, // Add your token to the request headers
          "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.csv';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        console.error('Error downloading CSV:', response.status);
        setDownloadtrue(false)
      }
      setDownloadtrue(false)
    } catch (error) {
      console.error('Error downloading CSV:', error);
      setDownloadtrue(false)
    }

           Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Success',
                    background: '#e9ecef',
                    // title: 'Done Successfully ',
                    //background: primary,
                    showConfirmButton: false,
                    timer: 2500
                })








    // window.open(process.env.REACT_APP_IP + `/User/reportdownloadbycampid`+`?campid=`+campaignname+`&temptoken=`+temptoken+
    // `&startdate=`+dateFormat(startdate, "yyyy-mm-dd")+`&enddate=`+dateFormat(enddate, "yyyy-mm-dd")+`&keypress=`+keypress);

  }

  const filterreportsubmit = async (event) => {
    event.preventDefault();

    //const DATE_FORMATER = require( 'dateformat' );
    //var datetimef = DATE_FORMATER( startdate, "yyyy-mm-dd" );


    console.log(">>>>startdate>>>>>>>" + dateFormat(startdate, "yyyy-mm-dd") + ">>>>>enddate>>>>>>>"
      + dateFormat(enddate, "yyyy-mm-dd") + ">>>>>>campaignname>>>>>>" + campaignname + ">>>>>>keypress>>>>>>" + keypress);


    getAllPosts()
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Success',
      background: '#e9ecef',
      // title: 'Done Successfully ',
      //background: primary,
      showConfirmButton: false,
      timer: 2500
  })




  }

  const [postsPerPage] = useState(5);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)

  const getPostData = (data) => {
    return (
      <>
       
               
                <tbody>
                  {data.content.map(page => {
                    return (
                      <tr key={page.id}>
                        {/* <td>{page.id}</td> */}
                        <td>{page.custnumber}</td>
                        <td>{page.c2}</td>
                        <td>{page.c4}</td>
                        {/* <td>{page.campaignid} | {page.campaign}</td> */}

                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?  

                        <td>{page.campaign}</td>
                        :''}

                        <td>

                          {/* <AudioPlayer src={process.env.REACT_APP_REC_OBD +page.userid+ '/' + page.folder + '/' + page.recordingname} /> */}


                          <audio controls src={process.env.REACT_APP_REC_OBD +page.userid+ '/' + page.folder + '/' + page.recordingname} > </audio>
                          




                        </td>
                        <td>
                          {/* <AudioDownloadButton audioUrl={process.env.REACT_APP_REC_OBD+page.folder+'/'+page.recordingname}
                              audioName={page.recordingname}
                              />  */}

                          <button className='btn btn-success btn-mysm' onClick={() => testdownload(page.userid+ '/' +page.folder + '/' + page.recordingname, page.recordingname)}>
                            <FaDownload size="20" />
                          </button>

                        </td>
                        <td>{page.dialtime}</td>
                        <td>{page.customeranswertime}</td>
                        <td>{page.customerhanguptime}</td>
                        {/* <td>{page.duration}</td> */}
                        <td>{page.duration}</td>
                        <td>{page.pressedkey}</td>



                      </tr>
                    );
                  })}
                </tbody>
              
         
      </>

    )

  }



  const [pagesize, setPagesize] = useState("10");


  const getAllPosts = async () => {
    // if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset === 0)
    // {

    //   setOffset(1);
    // }

    //  console.log(">>>>>>>>>>"+offset);

    const res = await axios.get(process.env.REACT_APP_IP + `/User/checkreport?pageNumber=${offset - 1}&&size=${pagesize}&&startdate=${sddate}&&enddate=${eddate}&&keypress=${keypress}&&campid=${campaignname}&&callduration=${callduration}`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })

    const data = res.data;

    //console.log(data);
    // const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(data)

    // Using Hooks to set value
    setAllPosts(postData)
    //setPageCount(Math.ceil(data.length / postsPerPage))
    setPageCount(Math.ceil(data.totalPages))
  }


  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const onInputChange = (event) => {
    let offset = Math.ceil(event.target.value);
   // alert(offset)
   if(offset === '')
   {
    offset = 1;
   }

    
    if(offset < 1)
    {
      offset = 1;
    }
    if(offset > 0)
    {
      setOffset(offset);
    }
 //alert(offset)

    //     if(isNaN(offset))
    // {
    //   setOffset(1);

    // }
    // if(offset < 0)
    // {
    //   setOffset(0);
    // }else{
    // setOffset(offset);
    // }
    // console.log(selectedPage);
    // setOffset(selectedPage)
    //handlePageClick(selectedPage);

    //console.log(event.target.value);
  }

  
  const onPagesizeChange = (event) => {
    let offsetp = Math.ceil(event.target.value);
      setPagesize(offsetp)
      //setPageCount(offsetp);
      getAllPosts()
 
  }

 

  const forpagesizechange = () => {
    let offsetp = 1;
    
    setOffset(offsetp)

  }
  


  useEffect(() => {
    getAllPosts()
  }, [offset,pagesize])


  return (

    <>


      <div className='row me-1'>



        {/* <ul className="nav nav-tabs mx-3">
  <li className="nav-item">
      <NavLink className="nav-link"  to="/Report">All Report</NavLink>
  </li>
  <li className="nav-item">
  <NavLink className="nav-link"  to="/FilterReport">Filter Report</NavLink>
  </li>
  
</ul> */}

        <div>
          <div className={downloadtrue ? "text-center mt-5" : "text-center mt-5 hide"}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="200"
              visible={true}
            />

          </div>


          <div className={downloadtrue ? "row hide" : "row "}>
            <div className='col-md-3'>
              <div className='card  mt-2 ms-2'>
                <form onSubmit={filterreportsubmit} >
                  <h4 className='card-header text-center'>Filter Report</h4>
                  {/* <div className="mb-3 mt-2">
                        <label htmlFor="campaignname" className="form-label">Campaign Name</label>
                        <select className="form-select" name="campaignname" 
                            onChange={(e) => setCampaignname(e.target.value)} >
                            <option value="All">ALL</option>
                           
                        </select>

                    </div> */}


              

             {localStorage.getItem('roles') === 'ROLE_ADMIN' ?  

                  <div className=" mb-2 mt-2">
                    <label >Campaign Name</label>
                    <select id="campnameid" className="form-control" name="campaignname" onChange={(e) => setCampaignname(e.target.value)} required>
                      <option value="">-- Select Campaign --</option>
                      <option value="ALL">ALL</option>

                    </select>

                  </div>

                          : ''}

                  <div className="mb-1 mt-2">
                    <label >DurationWise</label>
                    <select className="form-control" name="keypress" value={callduration}
                      onChange={(e) => setCallduration(e.target.value)} >
                      <option value="0">ALL</option>
                      <option value="15">Greater Than 15-Sec</option>
                      <option value="30">Greater Than 30-Sec</option>
                      <option value="45">Greater Than 45-Sec</option>
                      <option value="60">Greater Than 1-Min</option>
                      <option value="90">Greater Than 1.5-Min</option>
                      <option value="120">Greater Than 2-Min</option>
                      <option value="180">Greater Than 3-Min</option>
                      <option value="300">Greater Than 5-Min</option>
                      <option value="600">Greater Than 10-Min</option>
                      

                    </select>

                  </div>

                  <div className="mb-1 mt-2">
                    <label >KeyPress</label>
                    <select className="form-control" name="keypress" value={keypress}
                      onChange={(e) => setKeypress(e.target.value)} >
                      <option value="All">ALL</option>
                      {/* <option value="NA">NOANSWER</option> */}
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>

                    </select>

                  </div>

                  <div className="mb-1 mt-1">
                    <label htmlFor="campaignname" >Start Date</label>

                    <DatePicker className="input-group date" onChange={setstartdate} value={startdate} />
                    {/* <DatePicker className="form-control" onChange={setstartdate} value={startdate} /> */}

                  </div>

                  <div className="mb-1 mt-1">
                    <label htmlFor="campaignname" >End Date</label>

                    <DatePicker className="input-group date" onChange={setenddate} value={enddate} />

                  </div>

                  <div className="d-grid gap-2 mb-2 mt-2">
                    <input className="btn btn-primary " type="submit" value="Submit" />

                    {/* <input className="btn btn-primary " onClick={filterreportdownload} type="button" <FiDownload />value="Get-CSV" /> */}
                    <button className="btn btn-primary " onClick={filterreportdownload}> <FiDownload className='me-1' /> Get-CSV</button>

                  </div>
                </form>

              </div>









            </div>

            <div className='col-md-9 mt-1'>

              {/* <h1>Filter Report</h1> */}
              <h4 className='card-header text-center'>Filter Report</h4>
              {/* Display all the posts */}
              <div className=" text-nowrap">
              <table className="table table-hover table-bordered checkoddata ignoreline">
                <thead className="bg-darkblue text-white">
                  <tr>
                    {/* <th>ID</th> */}
                    <th>Customer Number</th>
                    <th>Agent Number</th>
                    <th>Agent Name</th>
                    {localStorage.getItem('roles') === 'ROLE_ADMIN' ?  
                    <th>Campaign</th>
                    :''}
                    <th></th>
                    <th></th>
                    <th>Dialtime</th>
                    <th>AnswerTime</th>
                    <th>HangTime</th>
                    {/* <th>Duration</th> */}
                    <th>TotalDuration</th>
                    <th>PressedKey</th>

                  </tr>
                </thead>
                {posts}
              </table>

             
              {/* <button type="button" className="btn btn-primary mx-2" onClick={handlePageClick}>Go</button> */}
            </div>
              



              <div className='row mb-5'>
                <div className='col-md-1 float-end'>
                  <select className='float-end form-select form-select-sm' 
                  //onChange={(e) => setPagesize(e.target.value)}
                  onChange={(e) => setPagesize(e.target.value)}
                  //  value={pagesize}
                  >
                     <option value="10">10</option>
                    <option value="5">5</option>
                    
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className='col-md-1'>
                  <input type="number" className="gotopage" id="gotopage" aria-describedby="gotopage"
                    placeholder="page" onChange={onInputChange} />
                </div>
                <div className='col-md-1'>

                </div>
                <div className='col-md-6'>
                
                 
                  <ReactPaginate
                    previousLabel={<i className="fa-solid fa-angles-left"></i>}
                    nextLabel={<i className="fa-solid fa-angles-right"></i>}
                    breakLabel={".."}
                    breakClassName={"break-me"}
                    breakLinkClassName={"break-mef"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={offset - 1}

                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                    
             

                </div>
                
                
              
              </div>


            </div>


          </div>
        </div>




      </div>
    </>
  )
}

export default FilterReport
