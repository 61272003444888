import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import { CSVLink, CSVDownload } from "react-csv";
import ReactAudioPlayer from 'react-audio-player';
import { motion } from 'framer-motion';
import { RingLoader } from 'react-spinners';
// import './LoadingSpinner.css';
import Swal from 'sweetalert2';
import { RotatingLines } from 'react-loader-spinner';




const UploadNumber = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })

    useEffect(() => {
        document.title = "Upload Number"
    }, []);


    const csvData = [
        ["Number"]
    ];

    const el = useRef();
    const [loading, setLoading] = useState(false);
    const [uploadFile, setUploadFile] = useState();
    const [listname, setListname] = useState();
    //const [data, getFile] = useState({ name: "", path: "" });  
    const onchangfun = (e) => {


        //const filea = e.target.files[0].name; // accesing file

        const pathb = e.target.files[0];
        console.log(pathb);
        // alert(">>>>><<<<<<<<<<<"+pathb);

        setUploadFile(e.target.files[0])

    }
    const [cusername, setCusername] = useState("");

    const changeuserfun = async (e) => {
        //alert(e)
        setCusername(e)
        // getAllAudioWithIvr(e)


    }

    const optionforusername = async (e) => {

        try {
            const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllAdmindrop", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })
            const json = await respp.json()
            console.log(json.length)

            var elelist = document.getElementById('userselectid');
            elelist.innerHTML = "" + ('<option value="">NA</option>');

            for (var i = 0; i < json.length; i++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                elelist.innerHTML = elelist.innerHTML +
                    '<option  value="' + json[i]['username'] + '">' + json[i]['username'] + '</option>';
            }


        } catch (error) {
            console.error('Error in  optionforusername ', error);
        }


    }

    useEffect(() => {
        optionforusername()
    }, [])

    const uploadaudioonsubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData()
        formData.append('file', uploadFile)
        formData.append('listname', listname)
        formData.append('username', cusername)



        const response = await fetch(process.env.REACT_APP_IP + "/User/UploadNumber", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'multipart/form-data',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formData

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json)
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        getAllPosts()
        setLoading(false)
        el.current.value = "";
        optionforusername()


        //alert(">>>>"+e.files[0]);
        // alert(uploadFile);


    }


    // deleteCampaign
    const deletelist = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP + "/User/DeleteList", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json.msg)
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: json.msg,
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                showConfirmButton: false,
                timer: 2500
            })
        }

        getAllPosts()





    }






    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllList?pageNumber=${offset - 1}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("10:00:00");
    const [end_time, setend_time] = useState("19:00:00");




    const getPostData = (data) => {
        return (
            <>

                <tbody>
                    {data.content.map(page => {
                        return (
                            <tr key={page.id}>
                                <td>{page.userid}</td>
                                <td>{page.listname}</td>
                                <td>{page.createdate}</td>
                                <td>
                                    <button className='btn btn-danger btn-mysm'>
                                        <i className="far fa-trash-alt" onClick={() => deletelist(page.id)} ></i>
                                    </button>





                                </td>




                            </tr>
                        );
                    })}
                </tbody>


            </>

        )

    }


    return (


        <>



            <div className='row mt-1 mx-1'>

                {loading && (
                    <>
                        <div className='p-2'>
                            <div className='col-md-4'></div>
                            <div className='col-md-6 mt-2'>
                                <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    wrapperClass="spinner-container"
                                    width="256"
                                    visible={true}
                                />

                            </div>
                            <div className='col-md-2'></div>

                        </div>




                    </>
                )


                }

                {/* <div className='col-md-3'></div> */}




                {/* <Vortex
  visible={true}
  height="40"
  width="40"
  ariaLabel="Uploading........"
  wrapperStyle={{}}
  wrapperClass="spinner-container"
  colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
/> */}
                {/* <TailSpin
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass="spinner-container"
  visible={true}
/> */}



                {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}
                {loading ?
                    <></>
                    :
                    <>

                        <div className='col-md-3 col-12 text-black'>


                            <form className='card p-1' onSubmit={uploadaudioonsubmit}>
                                <h4 className='card-header text-center'>Upload Number</h4>
                                {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                    <div className="mb-2">
                                        <label>User Name:</label>
                                        <select id="userselectid" className="form-select"
                                            onChange={(e) => changeuserfun(e.target.value)}
                                            // onChange={(e) => setUploaduserid(e.target.value)} 
                                            required>
                                        </select>
                                    </div>
                                    : ''}
                                <div className="mb-2 mt-2">
                                    <label htmlFor="listname">List Name</label>
                                    <input name="listname" type="text" className=" form-control form-control-sm"
                                        onChange={(e) => setListname(e.target.value)} required />


                                </div>

                                <div className="mb-2 mt-2">


                                    <input className=" form-control form-control-sm" ref={el}
                                        id="formFileSm" name="file" type="file"
                                        accept=".csv"
                                         onChange={onchangfun} required />
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input className="btn btn-primary form-control mt-3 text-white" type="submit" value="Submit" />
                                    </div>
                                    <div className='col-md-12 mt-1'>
                                        {/* <i className="fa-solid fa-download fa-2x btn btn-success  mt-3 " onClick={downloadsample} ></i> */}




                                        <CSVLink className="btn btn-success btn-mysm text-white float-end" data={csvData} filename={"SampleFile.csv"} target="_blank" >
                                            <i className="fa-solid fa-download"></i> CSV</CSVLink>
                                        {/* or  */}
                                        {/* <CSVDownload data={csvData} target="_blank" />; */}
                                    </div>


                                </div>



                            </form>

                        </div>



                        <div className="col-md-9 col-12">
                        <motion.div
                            initial={{ x: 800 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.5 }}
                        >

                            <div className='row'>

                                {/* <AddCampaignform /> */}


                                < div className='col-md-12 col-12'>

                                    <div className='card'>

                                        <div className='text-black'>
                                            <h2 className='card-header text-center'>Existing List</h2>

                                            <div className="">

                                                <table className="table table-hover table-bordered checkoddata ignoreline">
                                                    <thead className="bg-darkblue text-white">
                                                        <tr>
                                                            <th>User</th>
                                                            <th>ListName</th>
                                                            <th>Date</th>
                                                            <th>Delete</th>


                                                        </tr>
                                                    </thead>
                                                    {posts}
                                                </table>
                                                <div className='row mt-1 mb-1'>
                                                    <div className='col-md-4 col-6'>

                                                        <div className='ms-2'>Goto Pageno :

                                                            <input type="number" className=" ms-2 " id="gotopage" aria-describedby="gotopage"
                                                                placeholder="gotopage" onChange={onInputChange} />
                                                        </div>



                                                    </div>
                                                    <div className='col-md-6 col-6'>




                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                            </motion.div>

                            <div className="row">
                                <div className="col-md-3 col-12"></div>

                                <div className="col-md-6 col-12">


                                    <ReactPaginate
                                       previousLabel={<i className="fa-solid fa-angles-left"></i>}
                                       nextLabel={<i className="fa-solid fa-angles-right"></i>}
                                        breakLabel={".."}
                                        breakClassName={"break-me"}
                                        breakLinkClassName={"break-mef"}
                                        pageCount={pageCount}
                                        onPageChange={handlePageClick}
                                        forcePage={offset - 1}

                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}

                                    />


                                </div>

                            </div>



                        </div>
                    </>
                }




            </div>
        </>
    )


}


export default UploadNumber
