import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import Swal from 'sweetalert2';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { motion } from 'framer-motion';
import { CgAddR } from "react-icons/cg";
import { FaRegWindowClose } from "react-icons/fa";
import moment from 'moment';

// import AddCampaignform from './AddCampaignform';


const AddCampaign = (props) => {
    const history = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('token')) {
            // getcampaigns();
            // eslint-disable-next-line
            //const
        } else {
            history('/login');
        }
    })


    useEffect(() => {
        document.title = "Add Campaign"
    }, []);
    // const options = [
    //     { value: 'ii', label: 'Chocolate' },
    //     { value: 'hh', label: 'Strawberry' },
    //     { value: 'gg', label: 'Vanilla' }
    //   ]
    const [uploaduserid, setUploaduserid] = useState();


    const handleDateChange = (date) => {
        setstart_time(date);
        // alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))
    };

    const handleEndDateChange = (date) => {
        setend_time(date);
        // alert(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))
    };






    const [selectedOption, setSelectedOption] = useState(null);

    const ref = useRef(null)
    const changelistref = useRef(null)
    

    const refClose = useRef(null)
    const [ecampaignid, setEcampaignid] = useState()
    const [ecampaign, setEcampaign] = useState()
    const [ecampaignmode, setEcampaignmode] = useState()
    const [estarttime, setestarttime] = useState()
    const [eendtime, setEendtime] = useState()
    const [eselectaudio, setEeselectaudio] = useState()
    const [eselectlist, setEeselectlist] = useState()
    const [retry, setRetry] = useState(1)
    const [cpm, setCpm] = useState(1)

    const [addcampb, setAddcampb] = useState(false)


   
    



    const openCamp = () => {

        setAddcampb(true)

    }

    const closeCamp = () => {

        setAddcampb(false)

    }






    const [esubmitbutton, setesubmitbutton] = useState()

    // const { campaign, updateCampaign } = props;
    // deleteCampaign
    const deleteCampaign = async (e) => {

        var formdata = new FormData()
        formdata.append('id', e)
        const response = await fetch(process.env.REACT_APP_IP + "/User/DeleteCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json.msg)

        if (json.msg === "Success") {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: "Deleted Successfully",
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: 'Deleted Successfully',
                showConfirmButton: false,
                timer: 1500
            })

        } else {

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: 'Error ',
                showConfirmButton: false,
                timer: 1500
            })
        }

        getAllPosts()





    }

    const changeuserfun = async (e) => {
        //alert(e)
        setCusername(e)
        getAllAudioWithIvr(e)


    }



const [lid, setLid] = useState()
    const [luser, setLuser] = useState()
    const [lcamp, setLcamp] = useState()
    const [changelistfun, setChangelistfun] = useState()
    

    const changeListCampaign = (e1, e2, e3) => {
       // changelistref.current.click
       setLid(e1)
       optionforuserlist(e2)
       setLuser(e2)
       setLcamp(e3)
        changelistref.current.click();
        //alert(e1 + e2 + e3)
        //setCusername(e)
        //getAllAudioWithIvr(e)


    }

    const changeListSubmit = async (e) => {
        e.preventDefault();

      //  console.log(ecampaignid + ">>>>>>>" + ecampaign + ">>>>>>>>>" + ecampaignmode + ">>>>>>>>>" + estarttime
        //    + ">>>>>>>>" + eendtime + ">>>>>>>>>>>" + eselectaudio)

        var formdata = new FormData()
        formdata.append('campname', lcamp)
        formdata.append('username', luser)
        formdata.append('listname', changelistfun)
        const response = await fetch(process.env.REACT_APP_IP + "/User/EditListCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                //'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            // ,
             body: formdata

           // body: JSON.stringify({ id: ecampaignid, start_time: estarttime, end_time: eendtime, sms: cpm, maxtry: retry, action: ecampaignmode })
        });
        const json = await response.json()
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }

        changelistref.current.click();
        // setcampaign_mode("")
        //  setCpm("")
        //  setRetry("")
        getAllPosts()

    }


    const optionforuserlist = async (e) => {
        

        try {

            var formdata = new FormData()
            formdata.append('username', e)

            const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllListname", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    //'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata
                

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })
            const json = await respp.json()
            console.log(json.length)

            var elelist = document.getElementById('changelistselectid');
            elelist.innerHTML = "" + ('<option value="">NA</option>');

            for (var i = 0; i < json.length; i++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                elelist.innerHTML = elelist.innerHTML +
                    '<option  value="' + json[i]['listname'] + '">' + json[i]['listname'] + '</option>';
            }


        } catch (error) {
            console.error('Error in  optionforuserlist ', error);
        }


    }

    useEffect(() => {
        optionforuserlist()
    }, [])


    


    const getAllAudioWithIvr = async (e) => {
        try {
            var formdata = new FormData()
            formdata.append('username', e)
            //alert(">>>>"+e)
            const resppe = await fetch(process.env.REACT_APP_IP + "/User/RestAllAudioSelect", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    //'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })

            const ringgroupres = await fetch(process.env.REACT_APP_IP + "/User/AllObdivrByuername", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    //'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: formdata

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })
            const jsonb = await ringgroupres.json()
            //console.log(jsona.length)



            const jsona = await resppe.json()
            //console.log(jsona.length)

            var ele = document.getElementById('audioivrid');

            // ele.innerHTML = '<optgroup label="Agents">'
            ele.innerHTML = ""
            //+('<option  value="NA">------Select-----</option>');

            for (var l = 0; l < jsona.length; l++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                ele.innerHTML = ele.innerHTML +
                    '<option className="myopt"   value="' + jsona[l]['id'] + '|' + "Audio" + '">' + jsona[l]['audio'] + '</option>';
            }

            // ele.innerHTML = '<option  value="NA"></option>'+'<optgroup label="Agents">'+ele.innerHTML+('</optgroup>');


            //var bc=ele.innerHTMLele.innerHTML;

            // var ele = document.getElementById('groupid');
            // ele.innerHTML = ""+('<option value="NA">NA</option>');
            // var ele2 = "";
            //  ele.innerHTML = ""
            // var cd="";
            //  for (var l = 0; l < jsonb.length; l++) {
            //      // POPULATE SELECT ELEMENT WITH JSON.
            //      cd = cd +
            //          '<option  value="' + jsonb[l]['id'] +'|'+"IVR"+ '">' + jsonb[l]['ivrname'] + '</option>';
            //  }

            ele.innerHTML = '<option  value="NA"></option>' + '<optgroup label="Audio">' + ele.innerHTML + ('</optgroup>');


            //+'<optgroup label="IVR">'+cd+('</optgroup>');

            //ele.innerHTML = ele.innerHTML+'<optgroup label="RingGroup">'+cd+('</optgroup>');

            //ele.innerHTML = ele.innerHTML+'<optgroup label="RingGroup">'+ele2.innerHTML+('</optgroup>');


        } catch (error) {
            console.error('Error In getAllPosts ', error);
        }

    }

    useEffect(() => {
        getAllAudioWithIvr()
    }, [])

    const optionforusername = async (e) => {

        try {
            const respp = await fetch(process.env.REACT_APP_IP + "/User/RestAllAdmindrop", {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },

                headers: {
                    'Content-Type': 'application/json',

                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                }

                // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
            })
            const json = await respp.json()
            console.log(json.length)

            var elelist = document.getElementById('userselectid');
            elelist.innerHTML = "" + ('<option value="">NA</option>');

            for (var i = 0; i < json.length; i++) {
                // POPULATE SELECT ELEMENT WITH JSON.
                elelist.innerHTML = elelist.innerHTML +
                    '<option  value="' + json[i]['username'] + '">' + json[i]['username'] + '</option>';
            }


        } catch (error) {
            console.error('Error in  optionforusername ', error);
        }


    }

    useEffect(() => {
        optionforusername()
    }, [])

    const updateCampaign = async (e) => {

        ref.current.click();
        //alert(e);
        const response = await fetch(process.env.REACT_APP_IP + "/User/getCampaignById", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": e

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        // console.log(json);
        // console.log(json.campname);
        // console.log(json.campaign_mode);

        setEcampaign(json.campname)
        setEcampaignmode(json.action)

        setEcampaignid(json.id)
        setestarttime(json.start_time)
        setEendtime(json.end_time)
        setEeselectaudio(json.audioid | json.audio_name)
        setEeselectlist(json.listname)
        setmaxtry(json.maxtry)
        setRetry(json.maxtry)
        setCpm(json.sms)
        changeuserfun(json.username);

        //getAllPosts()
        //setIsToggled(!isToggled);




    }

    // const handleClick = (e)=>{ 
    //     editNote(e.id, e.ecampname, e.ecamp_mode)
    //     refClose.current.click();
    // }



    
   




    const submiteditform = async (e) => {
        e.preventDefault();

        console.log(ecampaignid + ">>>>>>>" + ecampaign + ">>>>>>>>>" + ecampaignmode + ">>>>>>>>>" + estarttime
            + ">>>>>>>>" + eendtime + ">>>>>>>>>>>" + eselectaudio)

        var formdata = new FormData()
        formdata.append('id', ecampaignid)
        formdata.append('campaign', ecampaign)
        formdata.append('action', ecampaignmode)
        formdata.append('start_time', estarttime)
        formdata.append('end_time', eendtime)
        formdata.append('action', campaign_mode)
        formdata.append('audio', audio)

        // formdata.append('listname', eselectlist)
        // formdata.append('maxtry', maxtry)






        const response = await fetch(process.env.REACT_APP_IP + "/User/EditCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            // ,
            // body: formdata

            body: JSON.stringify({ id: ecampaignid, start_time: estarttime, end_time: eendtime, sms: cpm, maxtry: retry, action: ecampaignmode })
        });
        const json = await response.json()
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }

        ref.current.click();
        // setcampaign_mode("")
        //  setCpm("")
        //  setRetry("")
        getAllPosts()

    }


    const [isToggled, setIsToggled] = useState(false);
    const onToggle = async (e) => {
        const id = e
        // alert(id)
        const response = await fetch(process.env.REACT_APP_IP + "/User/StartStopCampaign", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                "id": id

            })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        //props.showAlert("Done Successfully ", "success");
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: json.camp_status + " " + "Successfully",
            background: '#e9ecef',
            // title: 'Done Successfully ',
            //background: primary,
            showConfirmButton: false,
            timer: 1500
        })
        getAllPosts()
        setIsToggled(!isToggled);


    }

    const [camuser, setCamuser] = useState("");
    const searchsubmit =  (e) => {
        e.preventDefault();
        //setCamuser(e);
        getAllPosts()

    }


    // const [postsPerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const getAllPosts = async () => {
        const res = await axios.get(process.env.REACT_APP_IP + `/User/RestAllCamp?pageNumber=${offset - 1}&&username=${camuser}&&size=10`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } })
        const data = res.data;
        const postData = getPostData(data)
        // Using Hooks to set value
        setAllPosts(postData)
        // setPageCount(Math.ceil(data.length / postsPerPage))
        setPageCount(Math.ceil(data.totalPages))
    }

    const onInputChange = (event) => {

        const offset = Math.ceil(event.target.value);
        setOffset(offset);

    }



    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    useEffect(() => {
        getAllPosts()
    }, [offset])


    const [formyalert, setformyalert] = useState(false)
    //const { showAlert } = props

    const [cusername, setCusername] = useState("");
    const [campname, setCampname] = useState("");
    const [campaign_mode, setcampaign_mode] = useState("");
    const [maxtry, setmaxtry] = useState("");
    const [start_time, setstart_time] = useState("NA");
    const [end_time, setend_time] = useState("NA");
    const [audio, setAudio] = useState();
    const [listname, setList] = useState("DEFAULT");

    const [ttstext, setttstext] = useState("");
    const [pastenumber, setpastenumber] = useState("");

    // const setAudiooption = async(event) =>{
    //     setAudio()
    //     console.log(event)

    // }
    const addcampaignSubmit = async (event) => {
        event.preventDefault();
        if (start_time === "NA") {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: '!!!!!Start Time required',
                showConfirmButton: false,
                timer: 1500
            })
            // alert("!!!!!Start Time required")
            return
        }

        var formdata = new FormData()
        formdata.append('username', cusername)
        formdata.append('campname', campname)
        formdata.append('action', campaign_mode)
        formdata.append('sms', cpm)
        formdata.append('maxtry', retry)

        formdata.append('start_time', moment(start_time).format('YYYY-MM-DD HH:mm:ss'))

        formdata.append('end_time', moment(end_time).format('YYYY-MM-DD HH:mm:ss'))
        //  formdata.append('end_time', end_time)

        formdata.append('audioid', audio)
        formdata.append('listname', listname)
        // formdata.append('ttstext', ttstext)
        formdata.append('pastenumber', pastenumber)


        const response = await fetch(process.env.REACT_APP_IP + "/User/AddDeleteCampaignAction", {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },

            headers: {
                // 'Content-Type': 'application/json',

                "Authorization": `Bearer ${localStorage.getItem('token')}`
            },
            body: formdata
            // body: JSON.stringify({

            // })

            // body: JSON.stringify({ campname: campname,campaign_mode: campaign_mode })
        });
        const json = await response.json()
        console.log(json);
        if (json.type === "OK") {
            // props.showAlert(json.msg, "success");
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Success',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 2500
            })
        } else {
            //props.showAlert(json.msg, "danger");
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'error',
                background: '#e9ecef',
                // title: 'Done Successfully ',
                //background: primary,
                text: json.msg,
                showConfirmButton: false,
                timer: 4500
            })
        }
        //history('/AddCampaign');
        // alert("Campaign Added");
        setformyalert(!formyalert);
        getAllPosts()
        setCampname('')
        setpastenumber('')
        setAddcampb(false)

    }




    const getPostData = (data) => {
        return (
            <>

                <tbody>
                    {data.content.map((page, index) => {
                        return (
                            <tr key={page.id}>
                                <td>{(offset - 1) * 10 + index + 1}</td>
                                <td>{page.username}</td>
                                <td>{page.campname}</td>
                                <td>{page.listname}</td>
                                <td>
                                    <button className=" mx-2 btn btn-primary"
                                        onClick={() => changeListCampaign(page.id, page.username, page.campname)}
                                    >
                                        <i className="fa fa-edit" ></i>
                                    </button>
                                </td>
                                <td> {page.audio_name}</td>
                                {/* <td> {page.maxtry}</td> */}
                                <td> {page.sms}</td>
                                <td>

                                    <label className="toggle-switch">
                                        <input type="checkbox" checked={page.camp_status === "START"} onChange={() => onToggle(page.id)} />
                                        <span className="switch" />
                                    </label>

                                </td>
                                <td>
                                    <button className=" mx-2 btn btn-primary" onClick={() => updateCampaign(page.id)}>
                                        <i className="fa fa-edit" ></i>
                                    </button>

                                </td>
                                <td>{page.createdate}</td>
                                {/* <td>{page.totalcampdata}</td> */}
                                <td>{page.action}</td>
                                {/* <td>{page.maxtry}</td> */}
                                {/* <td>{page.camp_status}</td> */}


                                <td>{page.start_time}</td>
                                <td>{page.end_time}</td>
                                {/* <td>{page.listname}</td> */}
                                {/* <td> {page.audioid}  | {page.audio_name}</td> */}


                                <td>
                                    <button className=" mx-2 btn btn-danger" onClick={() => deleteCampaign(page.id)}>
                                        <i className="fa fa-trash" ></i>
                                    </button>

                                    {/* <i className="fa fa-trash mx-2" onClick={() => deleteCampaign(page.id)}></i> */}


                                </td>




                            </tr>
                        );
                    })}
                </tbody>


            </>

        )

    }


    return (

        <>

            <motion.div
                initial={{ x: -800 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.9 }}
            >


                <div className='row me-1 ms-1'>


                    <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Launch demo modal
                    </button>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Edit campaign</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{
                                    backgroundColor: "#a3c0dd"


                                }}>
                                    <form className=" p-2" onSubmit={submiteditform}>
                                        <div className="mb-2">

                                            <input type="text" className="form-control" id="ecampaignid" name="ecampaignid" value={ecampaignid}
                                                onChange={(e) => setEcampaignid(e.target.value)} required={true} readOnly={true} />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="title" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="ecampaign" name="ecampaign" value={ecampaign}
                                                onChange={(e) => setEcampaign(e.target.value)} required readOnly={true} />
                                        </div>

                                        {/* <label>Select Audio/Ivr:</label>
                                <div className=" mb-1">
                                    {/* <Select defaultValue={selectedOption} options={options}
                                        name="audio" value={audio} onChange={setSelectedOption} />  */}


                                        {/* <select id="audioivrid" className="form-select" name="audio"
                                    onChange={(e) => setAudio(e.target.value)} >
                                        <option value="">-- Select --</option>
                                        <option value="">NA</option>

                                    </select>
                                    
                                </div> */}

                                        <label>Action:</label>
                                        <div className=" mb-1">
                                            <select id="actionid" className="form-select"
                                                onChange={(e) => setEcampaignmode(e.target.value)} value={ecampaignmode}
                                                required
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="SimpleOBD">Simple OBD</option>
                                                <option value="OBDwithKeypress">OBD with Keypress</option>
                                                <option value="OBDwithcallpatch">OBD &amp; callpatching</option>
                                            </select>

                                        </div>


                                        <div className="mb-2">
                                            <label htmlFor="campname">Start Time</label>
                                            <input type="text" className="form-control" id="start_time" placeholder="Enter start_time"
                                                name="start_time" value={estarttime}
                                                onChange={(e) => setestarttime(e.target.value)} />

                                        </div>

                                        <div className="mb-2">
                                            <label htmlFor="end_time">End Time</label>
                                            <input type="text" className="form-control" id="end_time" placeholder="Enter start_time"
                                                name="end_time" value={eendtime}
                                                onChange={(e) => setEendtime(e.target.value)} />

                                        </div>
                                        <label>SMS:</label>
                                        <div className=" mb-1">
                                            <select id="cpmid" className="form-select" name="cpmname" value={cpm}
                                                onChange={(e) => setCpm(e.target.value)} required>
                                                <option value="NA">-- Select --</option>
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>

                                            </select>

                                        </div>
                                        <label className=" mt-2">Retry:</label>
                                        <div className=" mb-1 ">
                                            <select id="retryid" className="form-select" name="audio" value={retry}
                                                onChange={(e) => setRetry(e.target.value)} required >
                                                <option value="NA">-- Select --</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>

                                            </select>

                                        </div>










                                        <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>


                    {/* Change List */}

                    <button ref={changelistref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#changelistrefModal">
                        Launch demo modal
                    </button>
                    <div className="modal fade" id="changelistrefModal" tabIndex="-1" aria-labelledby="changelistrefModal" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header ">
                                    <h5 className="modal-title " id="changelistrefModal">Change List</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{
                                    backgroundColor: "#a3c0dd"


                                }}>
                                    <form className=" p-2" 
                                          onSubmit={changeListSubmit}
                                    >
                                        <div className="mb-2">

                                            {/* <input type="text" className="form-control" id="ecampaignid" name="ecampaignid" value={ecampaignid}
                                                onChange={(e) => setEcampaignid(e.target.value)} required={true} readOnly={true} /> */}
                                                <label>User:</label>

                                                 <input type="text" className="form-control" id="ecampaignid" name="ecampaignid" value={luser}
                                                onChange={(e) => setLuser(e.target.value)} required={true} readOnly={true} /> 


                                        </div>
                                        <div className="mb-2">

                                        <label>Campaign:</label>
                                        <input type="text" className="form-control" id="lcampid" name="lcamp" value={lcamp}
                                                onChange={(e) => setLcamp(e.target.value)} required={true} readOnly={true} /> 

                                            {/* <label htmlFor="title" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="ecampaign" name="ecampaign" value={ecampaign}
                                                onChange={(e) => setEcampaign(e.target.value)} required readOnly={true} /> */}


                                        </div>

                                        <div className="mb-2">
                                                <label>List:</label>
                                                <select id="changelistselectid" className="form-select"
                                                    onChange={(e) => setChangelistfun(e.target.value)}
                                                    // onChange={(e) => setUploaduserid(e.target.value)} 
                                                    required>
                                                </select>
                                            </div>

                                       

                                        <div className="d-grid gap-2">
                                            <input className="btn btn-primary mt-3" type="submit" value="Submit" />
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>







                    {/* <div className={formyalert? "myalert d-block p-2 bg-primary":"d-none"}>campaign added</div> */}

                    <div className={addcampb ? "row mb-5" : "d-none"}>

                        <div className='col-md-2'></div>
                        <div className='col-md-8 col-12 mt-1 mb-5'>
                            <div className=' text-white  text-center'>
                                {/* <h4>Add Campaign</h4> */}
                                <div>
                                    <button className='btn btn-danger btn-sm float-end' onClick={() => closeCamp()}>
                                        <FaRegWindowClose className='' size="25" /></button>
                                </div>
                                <div className='card-header text-center'>Add Campaign

                                </div>
                            </div>

                            <form className='card p-3'
                                // style={{
                                //     backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)"
                                //     , minHeight: "700px"

                                // }}



                                onSubmit={addcampaignSubmit}>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        {localStorage.getItem('roles') === 'ROLE_ADMIN' ?
                                            <div className="mb-2">
                                                <label>User Name:</label>
                                                <select id="userselectid" className="form-select"
                                                    onChange={(e) => changeuserfun(e.target.value)}
                                                    // onChange={(e) => setUploaduserid(e.target.value)} 
                                                    required>
                                                </select>
                                            </div>
                                            : ''}
                                        <label>Campaign Name</label>
                                        <div className=" mt-1 mb-1 ">
                                            <input type="text" className="form-control" id="campname" placeholder="Enter campname"
                                                name="campname" value={campname}
                                                onChange={(e) => setCampname(e.target.value)} required />

                                        </div>
                                        <label>Start Time</label>
                                        <div className="mt-1 mb-2">
                                            <DateTime
                                                // value={selectedDate}
                                                onChange={handleDateChange}
                                                timeFormat="HH:mm:ss"
                                                required={true} />
                                        </div>
                                        <label>End Time</label>
                                        <div className=" mb-2">
                                            <DateTime
                                                // value={selectedDate}
                                                onChange={handleEndDateChange}
                                                timeFormat="HH:mm:ss"
                                                required={true} />

                                        </div>

                                        <label>Select Audio:</label>
                                        <div className=" mb-1">
                                            {/* <Select defaultValue={selectedOption} options={options}
                                    name="audio" value={audio} onChange={setSelectedOption} />  */}


                                            <select id="audioivrid" className="form-select" name="audio"
                                                onChange={(e) => setAudio(e.target.value)} >
                                                <option value="">-- Select --</option>
                                                <option value="">NA</option>

                                            </select>

                                        </div>




                                    </div>

                                    <div className='col-md-6'>

                                        <label>Action:</label>
                                        <div className=" mb-1">
                                            <select id="actionid" className="form-select"
                                                onChange={(e) => setcampaign_mode(e.target.value)}
                                                required
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="SimpleOBD">Simple OBD</option>
                                                <option value="OBDwithKeypress">OBD with Keypress</option>
                                                <option value="OBDwithcallpatch">OBD &amp; callpatching</option>
                                            </select>

                                        </div>
                                        <label className=" mt-2">Retry:</label>
                                        <div className=" mb-1 ">
                                            <select id="retryid" className="form-select" name="audio"
                                                onChange={(e) => setRetry(e.target.value)} >
                                                <option value="NA">-- Select --</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>

                                            </select>

                                        </div>
                                        <label>SMS:</label>
                                        <div className=" mb-1">
                                            <select id="cpmid" className="form-select" name="cpmname" value={cpm}
                                                onChange={(e) => setCpm(e.target.value)} >
                                                <option value="NA">-- Select --</option>
                                                <option value="YES">YES</option>
                                                <option value="NO">NO</option>


                                            </select>

                                        </div>
                                        <label>Paste Number</label>
                                        <div className=" mb-2">
                                            <textarea id="pasteid" className="form-control" name="pastename" rows="4"
                                                placeholder='Paste Number' value={pastenumber}
                                                onChange={(e) => setpastenumber(e.target.value)} >


                                            </textarea>

                                        </div>

                                        {/* <span>OR</span>
                        <div className="mb-3 mt-1">
                            <label>Upload CSV</label>
                            <input className=" form-control form-control-sm"
                               // ref={el}
                                id="csvfileid" name="csvfilename" type="file"
                                // onChange={onchangfun} 
                                // required 
                                 />
                        </div> */}

                                    </div>
                                </div>







                                <input className="btn btn-primary mt-5 mb-5" type="submit" value="Submit" />
                            </form>


                        </div>

                    </div>




                    <div className={addcampb ? "d-none" : ""}>
                        <div className='row '>

                            {/* <AddCampaignform /> */}


                            < div className='col-md-12 col-12 mt-1'>

                                <div className='card p-2 mt-1'
                                //  style={{ backgroundImage: "linear-gradient(to right, #a7a2ee 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)" }}

                                >



                                    <div className=''>
                                        {/* <div className='mb-5'>
                    <button className='btn btn-success btn-md float-end' onClick={() => openCamp()}>
                    <CgAddR className='me-1 text-white' style={{color:"white"}} />AddCampaign</button>
            </div> */}



                                        <h2 className='card-header text-center  text-white'>

                                            <button className='btn btn-success btn-md float-start' onClick={() => openCamp()}>
                                                <CgAddR className='me-1 text-white' style={{ color: "white" }} />AddCampaign</button>

                                            Existing Campaign</h2>

                                        <div className="table-responsive text-nowrap">

                                            <table className="table table-hover table-bordered checkoddata ignoreline">
                                                <thead className="bg-darkblue text-white">
                                                    <tr>
                                                        <th>S.N</th>
                                                        <th>username</th>
                                                        <th>Camp</th>
                                                        <th>List</th>
                                                        <th>ChangeList</th>
                                                        <th> Audio</th>
                                                        {/* <th> retry</th> */}
                                                        <th> SMS</th>
                                                        <th>Status</th>
                                                        <th>Edit</th>
                                                        <th>Create-Date</th>
                                                        {/* <th>Data</th> */}
                                                        <th>Type</th>
                                                        {/* <th>Maxtry</th> */}

                                                        <th>Starttime</th>
                                                        <th>Endtime</th>
                                                        {/* <th>Listname</th> */}


                                                        <th>Delete</th>


                                                    </tr>
                                                </thead>
                                                {/* Display all the posts */}
                                                {posts}
                                            </table>
                                            <div className='row'>
                                    <div className='col-md-3'>
                                        <form onSubmit={searchsubmit}>
                                            <input type="text" className="form-control" name="serachuser"
                                                id="serachuserid" value={camuser}
                                                onChange={(e) => setCamuser(e.target.value)}
                                                placeholder="Search  By Username" />
                                        </form>
                                    </div>
                                    <div className='col-md-3'>
                                        <form 
                                       // onSubmit={noop}
                                        >
                                            <input type="number" className="form-control" id="gotopage" aria-describedby="gotopage"
                                                placeholder="gotopage" onChange={onInputChange} />

                                        </form>
                                    </div>

                                </div>

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>



                        <div className="row mb-5">
                            <div className="col-md-3 col-12"></div>

                            <div className="col-md-6 col-12">


                                <ReactPaginate
                                    previousLabel={<i className="fa-solid fa-angles-left"></i>}
                                    nextLabel={<i className="fa-solid fa-angles-right"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    breakLinkClassName={"break-mef"}
                                    pageCount={pageCount}
                                    onPageChange={handlePageClick}
                                    forcePage={offset - 1}

                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}

                                />


                            </div>

                        </div>



                    </div>




                </div>
            </motion.div>
        </>
    )


}


export default AddCampaign
